/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */
/* @use "jqwidgets-ng/jqwidgets/styles/jqx.base.css"; */

.hd-spinner {
    margin: 10px auto 0;
    width: 70px;
    text-align: center;
}

.hd-spinner > div {
    width: 12px;
    height: 12px;
    background-color: #1e96f7;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: hd-bouncedelay 1s infinite ease-in-out both;
    animation: hd-bouncedelay 1s infinite ease-in-out both;
}

.hd-spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.hd-spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

@-webkit-keyframes hd-bouncedelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1);
    }
}

@keyframes hd-bouncedelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

.hd-table {
    // height: calc(100vh - 220px);
    // width: 100;

    // @screen sm {
    //     height: calc(100vh - 220px);
    // }
    thead tr th {
        padding: 6px 4px;
    }
    tbody tr td {
        padding: 4px;
    }
}

.hd-text-blue-700 {
    color: rgb(23,97,136);
}

.mat-slider-1 {
    .mat-slider-thumb, .mat-slider-thumb-label, .mat-slider-track-fill {
        background-color: red !important;
    }
}

.mat-slider-2 {
    .mat-slider-thumb, .mat-slider-thumb-label, .mat-slider-track-fill {
        background-color: rgb(0, 132, 255) !important;
    }
}

/* mat-select dropdown panel height */
.fit-content.mat-mdc-select-panel {
    min-width: fit-content;
}

.fit-content-150.mat-mdc-select-panel {
    min-width: 150px;
}
